
/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.personalized-card {
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5;
  color: rgba(0, 0, 0, 0.65);
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  margin: 0;
  padding: 0;
  list-style: none;
  background: #fff;
  border-radius: 2px;
  position: relative;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
}
.personalized-card-hoverable {
  cursor: pointer;
}
.personalized-card-hoverable:hover {
  -webkit-box-shadow: 0 2px 8px rgba(0, 0, 0, 0.09);
          box-shadow: 0 2px 8px rgba(0, 0, 0, 0.09);
  border-color: rgba(0, 0, 0, 0.09);
}
.personalized-card-bordered {
  border: 1px solid #e8e8e8;
}
.personalized-card-head {
  background: transparent;
  border-bottom: 1px solid #e8e8e8;
  padding: 0 24px;
  border-radius: 2px 2px 0 0;
  zoom: 1;
  margin-bottom: -1px;
  min-height: 48px;
  font-size: 16px;
  color: rgba(0, 0, 0, 0.85);
  font-weight: 500;
}
.personalized-card-head:before,
.personalized-card-head:after {
  content: '';
  display: table;
}
.personalized-card-head:after {
  clear: both;
}
.personalized-card-head-wrapper {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
      -ms-flex-align: center;
          align-items: center;
}
.personalized-card-head-title {
  padding: 16px 0;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  -webkit-box-flex: 1;
  -webkit-flex: 1;
      -ms-flex: 1;
          flex: 1;
  display: inline-block;
}
.personalized-card-head .personalized-tabs {
  margin-bottom: -17px;
  clear: both;
  font-size: 14px;
  color: rgba(0, 0, 0, 0.65);
  font-weight: normal;
}
.personalized-card-head .personalized-tabs-bar {
  border-bottom: 1px solid #e8e8e8;
}
.personalized-card-extra {
  float: right;
  padding: 16px 0;
  font-size: 14px;
  color: rgba(0, 0, 0, 0.65);
  font-weight: normal;
  margin-left: auto;
}
.personalized-card-body {
  padding: 24px;
  zoom: 1;
}
.personalized-card-body:before,
.personalized-card-body:after {
  content: '';
  display: table;
}
.personalized-card-body:after {
  clear: both;
}
.personalized-card-contain-grid:not(.personalized-card-loading) .personalized-card-body {
  margin: -1px 0 0 -1px;
  padding: 0;
}
.personalized-card-grid {
  border-radius: 0;
  border: 0;
  -webkit-box-shadow: 1px 0 0 0 #e8e8e8, 0 1px 0 0 #e8e8e8, 1px 1px 0 0 #e8e8e8, 1px 0 0 0 #e8e8e8 inset, 0 1px 0 0 #e8e8e8 inset;
          box-shadow: 1px 0 0 0 #e8e8e8, 0 1px 0 0 #e8e8e8, 1px 1px 0 0 #e8e8e8, 1px 0 0 0 #e8e8e8 inset, 0 1px 0 0 #e8e8e8 inset;
  width: 33.33%;
  float: left;
  padding: 24px;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
}
.personalized-card-grid:hover {
  position: relative;
  z-index: 1;
  -webkit-box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
          box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
}
.personalized-card-contain-tabs > .personalized-card-head .personalized-card-head-title {
  padding-bottom: 0;
  min-height: 32px;
}
.personalized-card-contain-tabs .personalized-card-extra {
  padding-bottom: 0;
}
.personalized-card-cover > * {
  width: 100%;
  display: block;
}
.personalized-card-cover img {
  border-radius: 2px 2px 0 0;
}
.personalized-card-actions {
  border-top: 1px solid #e8e8e8;
  background: #fafafa;
  zoom: 1;
  list-style: none;
  margin: 0;
  padding: 0;
}
.personalized-card-actions:before,
.personalized-card-actions:after {
  content: '';
  display: table;
}
.personalized-card-actions:after {
  clear: both;
}
.personalized-card-actions > li {
  float: left;
  text-align: center;
  margin: 12px 0;
  color: rgba(0, 0, 0, 0.45);
}
.personalized-card-actions > li > span {
  display: inline-block;
  font-size: 14px;
  cursor: pointer;
  line-height: 22px;
  min-width: 32px;
  position: relative;
}
.personalized-card-actions > li > span:hover {
  color: #1890ff;
  -webkit-transition: color 0.3s;
  transition: color 0.3s;
}
.personalized-card-actions > li > span > .anticon {
  font-size: 16px;
  line-height: 22px;
}
.personalized-card-actions > li > span a {
  color: rgba(0, 0, 0, 0.45);
  line-height: 22px;
  display: inline-block;
  width: 100%;
}
.personalized-card-actions > li > span a:hover {
  color: #1890ff;
}
.personalized-card-actions > li:not(:last-child) {
  border-right: 1px solid #e8e8e8;
}
.personalized-card-wider-padding .personalized-card-head {
  padding: 0 32px;
}
.personalized-card-wider-padding .personalized-card-body {
  padding: 24px 32px;
}
.personalized-card-padding-transition .personalized-card-head,
.personalized-card-padding-transition .personalized-card-body {
  -webkit-transition: padding 0.3s;
  transition: padding 0.3s;
}
.personalized-card-type-inner .personalized-card-head {
  padding: 0 24px;
  background: #fafafa;
}
.personalized-card-type-inner .personalized-card-head-title {
  padding: 12px 0;
  font-size: 14px;
}
.personalized-card-type-inner .personalized-card-body {
  padding: 16px 24px;
}
.personalized-card-type-inner .personalized-card-extra {
  padding: 13.5px 0;
}
.personalized-card-meta {
  margin: -4px 0;
  zoom: 1;
}
.personalized-card-meta:before,
.personalized-card-meta:after {
  content: '';
  display: table;
}
.personalized-card-meta:after {
  clear: both;
}
.personalized-card-meta-avatar {
  padding-right: 16px;
  float: left;
}
.personalized-card-meta-detail {
  overflow: hidden;
}
.personalized-card-meta-detail > div:not(:last-child) {
  margin-bottom: 8px;
}
.personalized-card-meta-title {
  font-size: 16px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  color: rgba(0, 0, 0, 0.85);
  font-weight: 500;
}
.personalized-card-meta-description {
  color: rgba(0, 0, 0, 0.45);
}
.personalized-card-loading {
  overflow: hidden;
  position: relative;
}
.personalized-card-loading:after {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 24px;
  background: #fff;
  content: '';
}
.personalized-card-loading .personalized-card-body {
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
}
.personalized-card-loading-content p {
  margin: 0;
}
.personalized-card-loading-block {
  height: 14px;
  margin: 4px 0;
  border-radius: 2px;
  background: -webkit-gradient(linear, left top, right top, from(rgba(207, 216, 220, 0.2)), color-stop(rgba(207, 216, 220, 0.4)), to(rgba(207, 216, 220, 0.2)));
  background: -webkit-linear-gradient(left, rgba(207, 216, 220, 0.2), rgba(207, 216, 220, 0.4), rgba(207, 216, 220, 0.2));
  background: linear-gradient(90deg, rgba(207, 216, 220, 0.2), rgba(207, 216, 220, 0.4), rgba(207, 216, 220, 0.2));
  -webkit-animation: card-loading 1.4s ease infinite;
          animation: card-loading 1.4s ease infinite;
  background-size: 600% 600%;
}
@-webkit-keyframes card-loading {
  0%,
  100% {
    background-position: 0 50%;
  }
  50% {
    background-position: 100% 50%;
  }
}
@keyframes card-loading {
  0%,
  100% {
    background-position: 0 50%;
  }
  50% {
    background-position: 100% 50%;
  }
}
.personalized-card-small > .personalized-card-head {
  min-height: 36px;
  padding: 0 12px;
  font-size: 14px;
}
.personalized-card-small > .personalized-card-head > .personalized-card-head-wrapper > .personalized-card-head-title {
  padding: 8px 0;
}
.personalized-card-small > .personalized-card-head > .personalized-card-head-wrapper > .personalized-card-extra {
  padding: 8px 0;
  font-size: 14px;
}
.personalized-card-small > .personalized-card-body {
  padding: 12px;
}
